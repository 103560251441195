<template>
  <div class="collection-page">
    <div class="collection-page-head">
      <el-form :inline="true" ref="form">
        <el-form-item>
          <el-input
            clearable
            v-model.trim="searchForm.videoId"
            style="width: 260px"
            placeholder="请输入视频ID"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            @click="searchArticlePage"
          >
            检索
          </el-button>
        </el-form-item>
        <!-- <el-form-item>
          <el-popover placement="bottom" trigger="hover">
            <div>
              <p>
                【1】可以通过上面手动输入关键词或频道id后点击检索进行查询，也可以使用自己定义的关键词组点击查询进行查询
              </p>
              <p>
                【2】点击操作中的“监控”则可以对评论者的所有视频进行监控，监控后可在“频道方案”模块中查看，点击查看视频则可以查看视频详情及所有评论
              </p>
            </div>
            <span slot="reference" class="head-info">
              <img src="@/assets/imgs/help.png" alt="" />
              <span>使用说明</span>
            </span>
          </el-popover>
        </el-form-item> -->
      </el-form>
    </div>
    <div v-loading="loading" class="collection-page-content">
      <div class="collection-left">
        <div class="pagination-box">共收藏数据{{ total }}条</div>
        <div
          class="collection-left-content"
          v-infinite-scroll="getlist"
          :infinite-scroll-disabled="scrollDisabled"
          :infinite-scroll-distance="10"
        >
          <div>
            <div class="content-item" v-for="(item, i) in listData" :key="i">
              <div class="news-bg">
                <img
                  :src="item.video ? item.video.videoThumbnail : ''"
                  alt=""
                />
              </div>
              <div class="news-content">
                <div class="news-content-left">
                  <img
                    :src="item.video ? item.video.channelAvatar : ''"
                    alt=""
                  />
                </div>
                <div class="news-content-right">
                  <div class="content-title">
                    {{ item.video ? item.video.title : '' }}
                  </div>
                  <div class="content-time">
                    <div class="content-time-author">
                      <div>
                        {{ item.video ? item.video.author : '' }}
                      </div>
                      <div>
                        <el-popover
                          placement="bottom"
                          trigger="hover"
                          popper-class="Collection-page-popover"
                        >
                          <div>
                            <div>
                              <img
                                :src="require('@/assets/imgs/i22.png')"
                                alt=""
                              />
                              <span
                                class="btn-1"
                                @click="clearCollection(item.id)"
                              >
                                取消收藏
                              </span>
                            </div>
                            <div style="margin-top:10px">
                              <img
                                :src="require('@/assets/imgs/i20.png')"
                                alt=""
                              />
                              <span
                                class="btn-1"
                                @click="viewVideo(item.video)"
                              >
                                查看视频
                              </span>
                            </div>
                          </div>
                          <span slot="reference" class="Collection-head-info">
                            <img
                              :src="require('@/assets/imgs/i7.png')"
                              alt=""
                            />
                          </span>
                        </el-popover>
                      </div>
                    </div>
                    <div>
                      {{ item.video ? item.video.viewCount : '' }}次观看
                      &nbsp;&nbsp;
                      {{ item.video ? item.video.publishDate : '' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="news-origin">
                <div>来源:</div>
                <div>
                  <a :href="item.video ? item.video.url : ''" target="_blank">
                    {{ item.video ? item.video.url : '' }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <li v-if="listData.length > 0">{{ nomoreText }}</li>
        </div>
      </div>
      <!-- <div class="collection-right">
        <div class="collection-right-title">关键词方案</div>
        <div class="collection-right-title-border"></div>
        <div class="collection-right-record">
          <ul>
            <li v-for="(item, i) in planList" :key="i">
              <span
                @click.prevent="selectKey(i, item)"
                class="history-words"
                :class="selectKeyIndex == i ? 'select-obj' : ''"
              >
                {{ item.groupName }}
              </span>
            </li>
          </ul>
        </div>
      </div> -->
      <noData v-if="listData.length == 0"></noData>
    </div>
    <!--  -->
  </div>
</template>

<script>
import { collectPage, clearCollect } from '@/api/channel.js'
import { keyWordsPage } from '@/api/keywords.js'
export default {
  data() {
    return {
      loading: false,
      planList: [],
      searchForm: {
        videoId: ''
      },
      copySearchForm: {},
      // 当前页
      current: 1,
      // 每页记录数
      limit: 40,
      // 总记录数
      total: 0,
      dialogVisible: false,
      submitForm: {},
      tagIndexObj: {},
      listData: [],
      //
      selectKeyIndex: null,
      //
      nomoreText: '加载中...',
      scrollDisabled: false
    }
  },

  created() {
    this.getPlan()
    // this.getlist()
  },
  methods: {
    //   数据
    getlist() {
      this.loading = true
      this.scrollDisabled = true
      collectPage(this.current, this.limit, this.copySearchForm).then(data => {
        this.total = data.all
        this.loading = false
        if (data.items.length > 0) {
          this.current++
          this.scrollDisabled = false
          this.listData = this.listData.concat(data.items)
          if (data.items.length < this.limit) {
            this.nomoreText = '没有更多了...'
          }
        } else {
          this.nomoreText = '没有更多了...'
        }
      })
    },
    // 查看新闻
    lookNews(id) {
      this.$router.push({
        path: '/video/detail',
        query: {
          videoId: id
        }
      })
    },
    searchArticlePage() {
      this.current = 1
      this.listData = []
      this.copySearchForm = this.searchForm
      this.selectKeyIndex = null
      this.getlist()
    },
    getcollectionPage() {
      this.loading = true
      let obj = {
        channelId: this.searchForm.channelId,
        inputKey: this.searchForm.inputKey
      }
      collectionPage(this.current, this.limit, obj).then(res => {
        this.loading = false
        this.total = res.all
        this.listData = res.items
      })
    },
    // 关键词数据
    getPlan() {
      keyWordsPage(1, 1000).then(data => {
        //
        this.planList = data.items
      })
    },

    currentChange(val) {
      this.current = val
      this.getlist()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getlist()
    },

    // 选择关键词
    selectKey(i, row) {
      this.selectKeyIndex = i

      this.loading = true
      let obj = {
        channelId: '',
        inputKey: row.groupName
      }
      collectionPage(this.current, this.limit, obj).then(res => {
        this.loading = false
        this.total = res.all
        this.listData = res.items
      })
    },
    //
    viewVideo(row) {
      this.$router.push({
        path: '/video/detail/' + row.videoId,
        query: {
          videoTitle: row.title
        }
      })
    },
    //取消收藏
    clearCollection(id) {
      clearCollect(id).then(data => {
        this.$message({
          type: 'success',
          message: '已取消！'
        })
        this.current = 1
        this.listData = []
        this.getlist()
      })
    }
  }
}
</script>

<style lang="scss">
.collection-page {
  width: 100%;
  height: calc(100vh - 79px);
  background: rgb(240, 242, 245);
  padding: 10px;
  .collection-page-head {
    width: 100%;
    background: white;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 9px 0 rgb(82 63 105 / 18%);
    .el-form-item {
      margin: 10px 0;
      padding: 0 15px;
    }
    .head-info {
      cursor: pointer;
      img {
        width: 14px;
        height: 14px;
        object-fit: cover;
        vertical-align: middle;
      }
      span {
        font-size: 12px;
        font-weight: 300;
        color: #4b4b4b;
      }
    }
    .head-tag-box-margin {
      margin: 10px 0 15px 15px;
    }
    .head-tag-box {
      .head-tag {
        padding: 7px 13px;
        background: rgb(241, 241, 241);
        border: 0px solid #000000;
        border-radius: 15px;
        border: 1px solid rgb(224, 224, 224);
        margin-right: 15px;
        user-select: none;
        cursor: pointer;
      }
      .select-tag {
        color: white;
        background: #131212;
        border: none;
      }
    }
  }

  .collection-page-content {
    width: 100%;
    height: calc(100% - 55px);
    margin-top: 5px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    .collection-left {
      .pagination-box {
        margin-bottom: 5px;
        height: 30px;
        line-height: 30px;
        padding: 0 5px;
        font-size: 12px;
        color: #4b4b4b;
        background: #ffffff;
        border-radius: 5px;
      }
      //   width: calc(100% - 270px);
      width: 100%;
      height: 100%;
      overflow: hidden;
      .collection-left-content {
        // display: flex;
        // flex-wrap: wrap;
        // align-content: flex-start;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        padding: 14px;
        background: #ffffff;
        width: calc(100% + 0px);
        height: calc(100% - 45px);
        overflow: hidden;
        overflow-y: auto;
        .content-item {
          width: calc(100% / 4 - 5px);
          margin-right: 5px;
          border: 1px solid #e8e8e8;
          height: 330px;
          margin-bottom: 5px;
          overflow: hidden;
          display: inline-block;
          .news-bg {
            width: 100%;
            height: 160px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .news-content {
            margin-top: 10px;
            display: flex;

            border-bottom: 1px solid #e5e5e5;

            .news-content-left {
              width: 36px;
              height: 36px;
              margin-left: 5px;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
              }
            }
            .news-content-right {
              margin-left: 10px;
              width: calc(100% - 60px);
              .content-title {
                height: 50px;
                font-size: 14px;
                font-weight: 600;
                color: #1a1a1b;

                line-height: 24px;
                overflow: hidden;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              .content-time {
                overflow: hidden;
                height: 50px;
                margin-bottom: 10px;
                > div {
                  white-space: nowrap;
                  height: 12px;
                  font-size: 12px;
                  font-weight: 300;
                  color: #666666;
                  line-height: 18px;
                  margin-top: 10px;
                }
                .content-time-author {
                  display: flex;
                  justify-content: space-between;
                }
              }
            }
          }
          .news-origin {
            word-wrap: break-word;
            display: flex;
            padding: 10px 5px;
            height: 50px;
            overflow: hidden;
            > div:nth-child(1) {
              width: 35px;
              font-size: 12px;
              font-weight: 300;
              color: #1a1a1b;
              line-height: 20px;
              align-self: center;
            }
            > div:nth-child(2) {
              align-self: center;
              width: calc(100% - 40px);
              a {
                font-size: 12px;
                font-weight: 300;
                color: #1a1a1b;
                line-height: 20px;
              }
            }
            &:hover {
              > div:nth-child(2) {
                color: #0a91df;
                a {
                  color: #0a91df;
                  text-decoration: underline;
                }
              }
            }
          }
        }
        li {
          list-style: none;
          text-align: center;
          color: #555555;
          font-size: 12px;
        }
      }
    }
    .collection-right {
      width: 260px;
      height: 100%;
      overflow: auto;
      border-radius: 5px;
      padding: 14px;
      overflow: auto;
      background: #ffffff;
      border: 1px solid #e5e5e5;

      .collection-right-title {
        padding-left: 10px;
        border-left: 4px solid #694638;
        font-size: 14px;
        font-weight: 300;
        color: #2a2a2a;
      }
      .collection-right-title-border {
        border-bottom: 1px solid #e5e5e5ce;
        margin: 13px 0;
      }
      .collection-right-record {
        height: calc(100% - 50px);
        overflow: hidden;
        overflow-y: auto;
        // background: #2a2a2a;
        padding-top: 10px;
        ul {
          padding: 0;
          margin: 0;
          li {
            list-style: none;
            margin-bottom: 15px;
            .history-words {
              padding: 5px 10px;
              background: #efefef;
              border: 0px solid #000000;
              border-radius: 20px;
              white-space: nowrap;
              font-size: 12px;
              font-weight: 300;
              color: #2a2a2a;
              cursor: pointer;
              user-select: none;
              position: relative;
              .close-log {
                position: absolute;
                top: -5px;
                right: -5px;
                display: none;
              }
              &:hover {
                .close-log {
                  display: block;
                }
              }
            }
            .select-obj {
              background: #292828;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  //
}
.keywords-popover {
  width: 80px !important;
  min-width: 50px !important;
  padding: 5px;
}
.video-popover {
  width: 120px !important;
  min-width: 50px !important;
  padding: 5px;
  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    vertical-align: middle;
  }
  .btn-1 {
    font-size: 14px;
    vertical-align: middle;
    margin-left: 5px;
    color: #000000;
    cursor: pointer;
    &:hover {
      color: rgb(0, 140, 255);
    }
  }
}
.collection-page-popover {
  width: 120px !important;
  min-width: 50px !important;
  padding: 5px;
  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    vertical-align: middle;
  }
  .btn-1 {
    font-size: 14px;
    vertical-align: middle;
    margin-left: 5px;
    color: #000000;
    cursor: pointer;
    &:hover {
      color: rgb(0, 140, 255);
    }
  }
}
.Collection-page-popover {
  width: 120px !important;
  min-width: 50px !important;
  padding: 5px;
  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    vertical-align: middle;
  }
  .btn-1 {
    font-size: 14px;
    vertical-align: middle;
    margin-left: 5px;
    color: #000000;
    cursor: pointer;
    &:hover {
      color: rgb(0, 140, 255);
    }
  }
}
.Collection-head-info {
  cursor: pointer;
  img {
    width: 14px;
    height: 14px;
    object-fit: cover;
    vertical-align: middle;
  }
  span {
    font-size: 12px;
    font-weight: 300;
    color: #4b4b4b;
  }
}
</style>
